// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blogpost-template-tsx": () => import("./../src/templates/BlogpostTemplate.tsx" /* webpackChunkName: "component---src-templates-blogpost-template-tsx" */),
  "component---src-templates-person-template-tsx": () => import("./../src/templates/PersonTemplate.tsx" /* webpackChunkName: "component---src-templates-person-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

